.jobcard {
  padding: $space_x2;
  margin: 0 auto;
  width: 100%;
  max-width: 450px;
  min-height: 270px;
  max-height: 280px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border: 3px solid transparent;
  color: $color-oscuro;
  background-color: $color-claro;

  a {
    text-decoration: none !important;
    text-transform: none;
    color: $color-oscuro;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  &.selected {
    border: 3px solid blue;
    background-color: get-opacity(blue, 0.2);

    span {
      color: $color-oscuro;
    }

    &:hover {
      background-color: get-opacity(blue, 0.2);
    }
  }

  &.dark {
    color: $color-claro;
    background-color: $color-bg-oscuro;

    a {
      color: $color-claro;
    }

    &.selected {
      border: 3px solid blue;
      background-color: get-opacity(blue, 0.1);

      span {
        color: $color-claro;
      }

      &:hover {
        background-color: get-opacity(blue, 0.1);
      }
    }

    .jobcard__title {
      span {
        color: get-opacity($color-secundario, 1);
      }

      small {
        color: get-opacity($color-secundario, 1);
      }
    }

    .jobcard__body {
      color: get-opacity($color-secundario, 1);
    }

    &:hover {
      background-color: get-opacity($color-oscuro, 0.2);
    }
  }

  &:hover {
    box-shadow: 0px 30px 50px -10px rgba(0, 0, 0, 0.4);
  }

  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: $space;

    img {
      width: 44px;
      height: 44px;
      border: 1px solid $color-claro;
      border-radius: 100%;
      object-fit: cover;
      background-color: $color-secundario;
    }

    span {
      color: $color-secundario;
      @include font-size(13px);
    }
  }

  &__title {
    flex-grow: 1;
    span {
      margin-bottom: $space;
      transition: all 0.3s ease-in-out;
      color: get-opacity($color-oscuro, 0.7);
      @include font-size(14px);
    }

    h3 {
      margin-bottom: 0;
      transition: all 0.3s ease-in-out;
      @include font-size(16px);
    }

    small {
      transition: all 0.3s ease-in-out;
      color: get-opacity($color-oscuro, 0.7);
      @include font-size(13px);
    }
  }

  &__body {
    flex-grow: 1;
    margin: 15px 0 $space_x2 0;
    width: 100%;
    height: 60px;
    color: get-opacity($color-oscuro, 0.7);
    @include font-size(13px);
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    P {
      margin: 0;
      color: $color-variante-dos;
      @include font-size(18px);
    }

    span {
      color: $color-secundario;
      @include font-size(13px);
    }
  }
}
