.userform {
  padding: $space_x3;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 100vh;
  animation: fadeIn 0.8s ease-in-out both;
  transform: translate(-50%, -50%);
  background-color: $color-claro;

  &__header {
    padding: 0 0 $space_x2 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $space_x3;
    border-bottom: 1px solid get-opacity($color-secundario, 0.4);
    animation: fadeIn 0.8s ease-in-out 0.3s both;

    h2 {
      font-weight: $normal;
      @include font-size(32px);
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      border-radius: 100%;
      outline: none !important;
      cursor: pointer;
      color: $color-oscuro;
      background-color: transparent;
      @include font-size(45px);
    }
  }

  &__column {
    animation: fadeIn 0.8s ease-in-out 0.3s both;

    h3 {
      font-weight: $normal;
      @include font-size(24px);
    }

    p {
      margin: 0;
      width: 100%;
      max-width: 400px;
      @include font-size(14px);
    }
  }

  &__row {
    animation: fadeIn 0.8s ease-in-out 0.1s both;
    @include display-grid;
    @include grid-template-columns(50% 50%);
  }

  &__form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;
    animation: fadeIn 0.8s ease-in-out 0.3s both;

    label {
      padding: 0;
      margin: 0;
      width: 100%;
      min-height: 90px;
      text-transform: capitalize;
      @include font-size(15px);

      span {
        color: $color-error;
        @include font-size(13px);

        &.required {
          @include font-size(15px);
        }
      }

      & > div {
        margin: 0 auto;
      }
    }

    &__row {
      display: flex;
      justify-content: space-between;
      width: 100%;

      p {
        width: 45%;
        @include font-size(18px);
      }
    }

    input,
    textarea {
      padding: $space $space;
      width: 100%;
      border: 1px solid $color-oscuro;
      font-family: $Fuente1;
      outline: none;
      color: $color-oscuro;
      background-color: get-opacity($color-claro, 1);

      &.error {
        border: 1px solid $color-error;
        color: $color-error;
        background-color: get-opacity($color-error, 0.1);
      }
    }
  }

  &__profile {
    position: relative;
    width: 300px;
    height: 300px;
    border-radius: 100%;
    cursor: pointer !important;
    background-color: $color-variante;

    &__icon {
      padding: $space_x9;
      position: absolute;
      width: 300px;
      height: 300px;
      object-fit: cover;
      opacity: 0;
      transition: all 0.3s ease-in-out;
      background-color: get-opacity($color-bg-claro, 0.1);
    }

    &__avatar {
      position: absolute;
      width: 300px;
      height: 300px;
      border-radius: 100%;
      object-fit: cover;
    }

    input {
      position: absolute;
      z-index: 2;
      width: 300px;
      height: 300px;
      border-radius: 100%;
      opacity: 0;
      cursor: pointer;
    }

    &:hover {
      .userform__profile__icon {
        opacity: 1;
      }
    }
  }

  &__select {
    position: absolute;
    width: 100%;
    cursor: pointer !important;
    border: 1px solid $color-oscuro;
    @include font-size(14px);

    &--is-disabled {
      cursor: not-allowed !important;
    }

    &__input-container {
      color: $color-oscuro !important;

      input {
        text-align: left !important;
        width: 100%;
        text-transform: uppercase !important;
      }
    }

    &__control {
      border-radius: 0 !important;
      border: none !important;
      box-shadow: none !important;
      cursor: pointer;
      background-color: get-opacity($color-claro, 1) !important;

      &--is--focused {
        background-color: get-opacity($color-oscuro, 0.5) !important;
      }

      &--is-disabled {
        cursor: not-allowed !important;
        border-bottom: 1px solid get-opacity($color-oscuro, 0.2) !important;
        background-color: get-opacity($color-claro, 0.3) !important;

        .userform__select__dropdown-indicator {
          color: get-opacity($color-oscuro, 0.2) !important;
        }
      }
    }

    &__single-value {
      color: $color-oscuro !important;
      transition: all 0.5s ease-in-out !important;

      &--is-disabled {
        cursor: not-allowed !important;
        color: get-opacity($color-oscuro, 0.2) !important;
      }
    }

    &__value-container {
      color: $color-oscuro !important;
    }

    &__indicators {
      cursor: pointer !important;
      span {
        display: none !important;
      }
    }

    &__menu {
      margin-top: 0 !important;
      border-radius: 0 !important;
      background-color: get-opacity($color-claro, 1) !important;

      &-list {
        padding: 0 !important;
        max-height: 200px !important;
        border-bottom: 0.5px solid get-opacity($color-oscuro, 0.2);
        background-color: get-opacity($color-claro, 0) !important;

        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          background: get-opacity($color-oscuro, 0.1);
        }

        &::-webkit-scrollbar-thumb {
          background: get-opacity($color-primario, 0.8);
        }

        &::-webkit-scrollbar-thumb:hover {
          background: get-opacity($color-primario, 1);
        }
      }

      &-is-open {
        position: relative !important;
        z-index: 1000 !important;
      }
    }

    &__option {
      padding: $space !important;
      cursor: pointer !important;
      color: $color-oscuro !important;
      border-bottom: 0.5px solid get-opacity($color-claro, 0.2);
      background-color: get-opacity($color-claro, 0.9) !important;

      &:last-child {
        border-bottom: 0.5px solid get-opacity($color-claro, 0);
      }
    }

    &__option--is-focused {
      background-color: get-opacity($color-oscuro, 0.1) !important;
    }

    &__option--is-selected {
      background-color: get-opacity($color-primario, 0.5) !important;
    }

    &__multi-value {
      background-color: get-opacity($color-secundario, 0.2) !important;

      &__label {
        color: $color-oscuro !important;
      }
    }

    input {
      width: auto;
    }

    &.dark {
      border: 1px solid $color-claro;
      background-color: get-opacity($color-claro, 0.1) !important;

      .userform__select__input-container {
        color: $color-claro !important;
      }

      .userform__select__control {
        background-color: get-opacity($color-oscuro, 0) !important;

        &--is--focused {
          background-color: get-opacity($color-claro, 0.2) !important;
        }

        &--is-disabled {
          border-bottom: 1px solid get-opacity($color-claro, 0.2) !important;
          background-color: get-opacity($color-claro, 0.05) !important;

          .userform__select__dropdown-indicator {
            color: get-opacity($color-claro, 0.2) !important;
          }
        }
      }

      .userform__select__single-value {
        color: $color-claro !important;

        &--is-disabled {
          color: get-opacity($color-claro, 0.2) !important;
        }
      }

      .userform__select__value-container {
        color: $color-claro !important;
      }

      .userform__select__menu {
        background-color: get-opacity($color-oscuro, 1) !important;

        &-list {
          background-color: get-opacity($color-oscuro, 0) !important;

          &::-webkit-scrollbar-track {
            background: get-opacity($color-claro, 0.1);
          }
        }

        &-is-open {
          position: relative !important;
          z-index: 1000 !important;
        }
      }

      .userform__select__option {
        color: $color-claro !important;
        border-bottom: 0.5px solid get-opacity($color-claro, 0.2);
        background-color: get-opacity($color-oscuro, 0.9) !important;

        &:last-child {
          border-bottom: 0.5px solid get-opacity($color-claro, 0);
        }
      }

      .userform__select__option--is-focused {
        background-color: get-opacity($color-claro, 0.1) !important;
      }

      .userform__select__option--is-selected {
        background-color: get-opacity($color-primario, 0.6) !important;
      }

      .userform__select__multi-value {
        background-color: get-opacity($color-secundario, 0.2) !important;

        &__label {
          color: $color-claro !important;
        }
      }
    }
  }

  &__footer {
    padding-top: $space;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      padding: $space $space_x5;
      margin-left: $space_x3;
      font-family: $Fuente1;
      border: none;
      outline: none !important;
      border-radius: $space_half;
      cursor: pointer;
      transition: all 0.5s ease-in-out;
      color: $color-claro;
      background-color: $color-primario;

      &:first-child {
        background-color: $color-variante;
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }

  &.dark {
    background-color: $color-bg-oscuro;

    .userform__header {
      border-bottom: 1px solid get-opacity($color-secundario, 0.4);

      button {
        color: $color-claro;
      }
    }

    .userform__profile {
      &__icon {
        background-color: get-opacity($color-bg-oscuro, 0.8);
      }
    }

    .userform__form {
      input,
      textarea {
        border: 1px solid $color-claro;
        color: $color-claro;
        background-color: get-opacity($color-claro, 0.1);

        &.error {
          border: 1px solid $color-error;
          color: $color-error;
          background-color: get-opacity($color-error, 0.1);
        }
      }
    }
  }

  @include media(desktop-l) {
    padding: $space_x5;
    width: 70%;
    height: auto;
    border-radius: $space;

    &__form {
      label {
        min-height: 93px;
        @include font-size(15px);

        span {
          color: $color-error;
          text-transform: none;
          @include font-size(13px);

          &.required {
            @include font-size(18px);
          }
        }
      }
    }
  }
}
