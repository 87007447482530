.errorpage {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  max-height: 500px;
  transform: translate(-50%, -50%);

  &.dark {
    h1, h4 {
      color: $color-claro;
    }
  }

  h1 {
    margin-bottom: $space;
    color: $color-oscuro;
    text-transform: uppercase;
    text-align: center;
    @include font-size(40px);
  }

  h4 {
    max-width: 300px;
    color: $color-oscuro;
    font-weight: 400;
    text-align: center;
    @include font-size(18px);
  }

  &__anim {
    display: flex;
    max-width: 450px;

    div {
      width: 100%;
      height: 100%;
    }
  }

  &__link {
    padding: $space $space_x3;
    background-color: $color-primario;
    color: $color-claro;
    @include font-size(18px);

    &:hover {
      color: $color-claro;
    }
  }
}
