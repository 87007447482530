.categories {
  padding: $space_x2 $space_x3;
  width: 100%;
  height: auto;
  user-select: none;
  overflow-x: hidden;
  transition: all 0.3s ease-in-out;
  color: $color-oscuro;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      transition: all 0.3s ease-in-out;
      @include font-size(24px);
    }
  }

  &__container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-x: hidden;
    transition: all 0.2s ease-in-out;
  }

  &__column {
    width: 100%;
    height: 100%;
    min-height: 80vh;
    border-radius: $space_half;
    transition: all 0.3s ease-in-out;
    background-color: $color-claro;

    &__header {
      padding: $space_x2 $space_x4;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 71px;

      h2 {
        margin: 0;
        font-weight: $normal;
        @include font-size(16px);
      }

      button {
        padding: $space_half;
        margin: 0;
        font-family: $Fuente1;
        border: none;
        border-radius: $space_half;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        color: $color-claro;
        background-color: $color-primario;
        @include font-size(12px);

        &:hover {
          opacity: 0.7;
        }
      }
    }

    &:first-child {
      margin-right: $space_x2;
      max-width: 300px;
      min-width: 250px;
    }

    &:nth-child(2) {
      width: 100%;
      min-width: 240px;
    }

    &.last {
      // display: none;
      margin-left: $space_x2;
      width: 100%;
      min-width: 400px;
      animation: reveal-panel 0.3s ease-in-out 0.2s both;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        position: relative;
        border-top: 1px solid get-opacity($color-secundario, 0.8);
        transition: all 0.3s ease-in-out;
        @include font-size(13px);

        &:last-child {
          border-bottom: 1px solid get-opacity($color-secundario, 0.8);
        }

        button {
          padding: $space_x2 $space $space_x2 $space_x4;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          text-align: left;
          font-family: $Fuente1;
          border: none;
          outline: none !important;
          cursor: pointer;
          color: $color-oscuro;
          background-color: transparent;
        }

        span {
          padding: 0;
          display: flex;
          align-items: center;
          border-radius: 100%;
          transition: all 0.3s ease-in-out;
          @include font-size(20px);

          &:hover {
            background-color: get-opacity($color-oscuro, 0.5);
          }
        }

        svg {
          fill: get-opacity($color-oscuro, 0.5);
          transition: all 0.3s ease-in-out;
        }

        &.active {
          color: $color-claro;
          background-color: $color-primario;

          button {
            color: $color-claro;
          }

          svg {
            fill: get-opacity($color-claro, 1);
          }

          &:hover {
            background-color: get-opacity($color-primario, 1);

            svg {
              fill: get-opacity($color-claro, 1);
            }
          }
        }

        &:hover {
          color: $color-oscuro;
          background-color: get-opacity($color-secundario, 0.8);

          svg {
            fill: get-opacity($color-oscuro, 1);
          }
        }
      }
    }

    table {
      width: 100%;
      @include font-size(14px);

      th {
        padding: $space_x2 $space_x4;
        text-align: left;
        font-weight: $normal;
        background-color: get-opacity($color-secundario, 0.7);
      }

      td {
        padding: $space_x2 $space_x4;

        p {
          display: flex;
          align-items: center;
          margin: 0;
        }

        button {
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;
          width: 20px;
          height: 20px;
          border: none;
          border-radius: 100%;
          outline: none;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          color: $color-primario;
          background-color: transparent;
          @include font-size(20px);

          &:last-child {
            margin-left: $space_x3;
            color: $color-error;
          }

          &:hover {
            opacity: 0.5;
          }
        }
      }

      tr {
        border-top: 1px solid get-opacity($color-secundario, 0.8);

        &:last-child {
          border-bottom: 1px solid get-opacity($color-secundario, 0.8);
        }
      }
    }
  }

  &.dark {
    color: $color-claro;

    .categories__column {
      color: $color-claro;
      background-color: $color-bg-oscuro;

      ul {
        li {
          border-top: 1px solid get-opacity($color-secundario, 0.2);

          &:last-child {
            border-bottom: 1px solid get-opacity($color-secundario, 0.2);
          }

          &.active {
            color: $color-claro;
            background-color: $color-primario;

            svg {
              fill: get-opacity($color-claro, 1);
            }

            &:hover {
              background-color: get-opacity($color-primario, 1);
            }
          }

          button {
            color: $color-claro;
          }

          span {
            &:hover {
              background-color: get-opacity($color-oscuro, 0.5);
            }
          }

          svg {
            fill: get-opacity($color-secundario, 0.2);
          }

          &:hover {
            color: $color-claro;
            background-color: get-opacity($color-secundario, 0.2);

            svg {
              fill: get-opacity($color-claro, 1);
            }
          }
        }
      }

      table {
        th {
          background-color: get-opacity($color-oscuro, 0.7);
        }

        td {
          button {
            color: $color-primario;

            &:last-child {
              color: $color-error;
            }
          }
        }

        tr {
          border-top: 1px solid get-opacity($color-secundario, 0.2);

          &:last-child {
            border-bottom: 1px solid get-opacity($color-secundario, 0.2);
          }
        }
      }
    }
  }

  @include media(desktop-l) {
    padding: $space_x2 $space_x3;
  }
}
