.options {
  padding: $space;
  position: absolute;
  bottom: 50px;
  right: 5px;
  border-radius: $space_half;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
  color: $color-oscuro;
  background-color: $color-claro;


  button {
    padding: $space_half 0 !important;
    display: flex;
    align-items: center;
    justify-content: flex-start !important;
    margin: 0;
    width: 100%;
    font-family: $Fuente1;
    font-weight: $normal;
    text-align: left !important;
    background-color: transparent;
    @include font-size(14px);

    svg {
      margin-right: $space;
      @include font-size(18px);
    }

    &:hover {
      opacity: 0.5;
    }
  }

  &__edit {
    color: $color-primario !important;

    svg {
      fill: $color-primario !important;
    }
  }

  &__delete {
    color: $color-error !important;

    svg {
      fill: $color-error !important;
    }
  }

  &__triangle {
    position: absolute;
    bottom: -8px;
    right: 8px;
    width: 15px;
    height: 10px;
    clip-path: polygon(99% 0, 0 0, 50% 100%);
    background-color: $color-claro;
  }

  &.dark {
    background-color: $color-oscuro;

    .options__triangle{
      background-color: $color-oscuro;
    }
  }
}
