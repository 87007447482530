.filters {
  padding: 15px 17px;
  width: 100%;
  display: flex;
  gap: 1rem;
  position: relative;

  & > * {
    height: 40px;
    margin-bottom: 0;
    padding: 0.5rem;
    border: 1px solid $color-variante;
    border-radius: 5px;
    background-color: $color-bg-claro;
    color: $color-variante;
  }

  .search {
    flex-grow: 2;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    input {
      flex-grow: 2;
      border: none;
      font-family: $Fuente1;
      outline: none !important;
      color: inherit;
      background-color: inherit;

      &:focus {
        outline: none;
      }
    }

    //simular focus en el container
    &:has(input:focus) {
      outline: 0px dotted #212121;
      outline: 0px auto -webkit-focus-ring-color;
    }
  }

  .filtercontainer {
    width: 78px;
    padding: 0px;

    .filter {
      width: 100%;
      height: 100%;
      cursor: pointer;
      border: none;
      border-radius: inherit;
      outline: none !important;
      color: inherit;
      background-color: inherit;

      &:hover {
        background-color: get-opacity($color-variante, 0.2);
      }

      svg {
        margin: 0 auto;
        display: block;
        height: 1rem;
        pointer-events: none;
      }
    }
  }

  &__options {
    position: absolute;
    // width: calc(100% - 34px);
    padding: 1rem;
    top: 62px;
    right: 17px;
    z-index: 2;
    width: 600px;
    height: auto;
    border: 1px solid $color-variante;
    color: $color-oscuro;
    border-radius: 5px;
    gap: 1rem;
    justify-content: space-around;
    flex-wrap: nowrap;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.1s ease-in-out;
    background-color: $color-bg-claro;
    @include display-grid;
    @include grid-template-columns(1fr 1fr 1fr);

    &.active {
      opacity: 1;
      visibility: visible;
      pointer-events: all;
      justify-content: space-between;
    }

    .btn {
      padding: 0.5rem;
      grid-column: 1 / span 3;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0 0 auto;
      width: 180px;
      user-select: none;
      font-family: $Fuente1;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      outline: none !important;
      transition: all 0.3s ease-in-out;
      color: $color-claro;
      background-color: get-opacity($color-primario, 0.9);
      @include font-size(15px);

      &:hover {
        background-color: get-opacity($color-primario, 0.5);
      }

      svg {
        margin-left: $space;
        path {
          stroke: $color-claro;
        }
      }
    }

    .container {
      display: flex;
      flex-direction: column;

      h3 {
        margin-bottom: $space;
        font-weight: $normal;
        user-select: none;
        @include font-size(16px);
      }

      // label {
      //   margin: 0 0.3rem;
      //   font-size: 1rem;
      //   font-weight: $light;
      //   display: flex;
      //   align-items: center;
      //   user-select: none;
      // }

      // input {
      //   margin-right: 0.5rem;
      //   height: 1rem;
      //   width: 1rem;
      //   border-radius: 5px;
      // }

      .pretty {
        margin-bottom: $space_half;
        margin-top: $space_half;

        svg {
          font-size: 18px;
        }
      }
    }

    li {
      margin-bottom: $space_half;
      .rct-text {
        align-items: center !important;

        label {
          display: flex;
          font-size: 1em;
        }

        span {
          padding: 0 $space_half 0 0 !important;
          display: flex;

          svg {
            margin-top: 2px;
            color: #bdc3c7;
            border-radius: 20%;
            width: calc(1em + 2px);
            height: calc(1em + 2px);
          }
        }

        button {
          padding: 0 $space_half 0 0 !important;
          outline: none !important;
          font-size: 11px !important;
        }
      }
    }
  }

  &.dark {
    & > * {
      color: $color-variante;
      background-color: $color-variante-tres;
    }

    .filter__options {
      background-color: $color-variante-tres;
    }
  }
}
