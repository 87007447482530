.profile {
  $transition-time: 0.3s ease-in-out;
  $transition-delay: 0s;

  padding: $space_x9 $space 0;
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 1;
  width: 310px;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  transition: all $transition-time;
  color: $color-oscuro;
  background-color: $color-claro;

  &__close {
    position: absolute;
    top: 80px;
    left: 0;
    z-index: 1;
    border: none !important;
    outline: none !important;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    color: $color-oscuro;
    background-color: transparent;
    @include font-size(30px);

    &:hover {
      opacity: 0.5;
    }
  }

  &__closed {
    right: -320px;
  }

  &__header {
    padding: $space_x2 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-bottom: 1px solid get-opacity($color-variante, 1);

    img {
      margin-bottom: $space;
      width: 150px;
      height: 150px;
      object-fit: cover;
      border-radius: 100%;
      box-shadow: 0px 0px 0px 10px get-opacity($color-variante, 0.5);
      background-color: $color-variante;
    }

    h2 {
      margin: 0;
      color: $color-oscuro;
      @include font-size(20px);
    }

    p {
      margin: 0;
      color: $color-variante;
      @include font-size(14px);
    }

    small {
      color: $color-variante;
      @include font-size(11px);
    }
  }

  &__actions {
    padding: $space $space_x2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid get-opacity($color-variante, 1);

    a {
      padding: $space_half $space;
      font-family: $Fuente1;
      border: none !important;
      outline: none !important;
      border-radius: $space_half;
      text-decoration: none;
      cursor: pointer;
      color: $color-claro !important;
      background-color: $color-primario;
    }

    button {
      padding: $space_half $space;
      font-family: $Fuente1;
      border: none !important;
      outline: none !important;
      border-radius: $space_half;
      cursor: pointer;
      color: $color-claro;
      background-color: $color-error;
    }
  }

  &__container {
    padding: 0 $space;

    h3 {
      margin: $space_x2 0 $space;
      color: $color-oscuro;
      @include font-size(18px);
    }

    ul {
      padding: 0 $space;
      margin: 0;
      list-style: none;
    }
  }

  &__comment {
    padding: $space;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: $space;
    border-radius: $space_half;
    background-color: $color-bg-claro;

    img {
      margin-right: $space;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      background-color: get-opacity($color-oscuro, 1);
    }

    &__text {
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      a {
        font-weight: $medium;
        color: $color-oscuro !important;
        @include font-size(15px);
      }

      span {
        color: $color-oscuro;
        @include font-size(11px);
      }
    }
  }

  &__offers {
    padding: $space;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: $space;
    border-radius: $space_half;
    background-color: $color-bg-claro;

    img {
      margin-right: $space;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      background-color: get-opacity($color-claro, 0.2);
    }

    &__text {
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      a {
        font-weight: $bolder !important;
        color: $color-oscuro !important;
        @include font-size(15px);
      }

      span {
        color: $color-oscuro;
        @include font-size(11px);
      }
    }
  }

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    background: $color-bg-claro;
  }

  &.dark {
    color: $color-claro;
    background-color: $color-bg-oscuro;

    .profile__close {
      color: $color-claro;
    }

    .profile__header {
      border-bottom: 1px solid get-opacity($color-variante, 0.07);

      img {
        box-shadow: 0px 0px 0px 10px get-opacity($color-variante, 0.5);
        background-color: $color-variante;
      }

      h2 {
        color: $color-claro;
      }

      p {
        color: $color-secundario;
      }

      small {
        color: $color-variante;
      }
    }

    .profile__actions {
      border-bottom: 1px solid get-opacity($color-variante, 0.07);
      pointer-events: auto;
    }

    .profile__container {
      h3 {
        color: $color-claro;
      }
    }

    .profile__comment {
      background-color: $color-oscuro;

      img {
        background-color: get-opacity($color-claro, 0.2);
      }

      &__text {
        a {
          color: $color-claro !important;
        }

        span {
          color: $color-variante;
        }
      }
    }

    .profile__offers {
      background-color: $color-oscuro;

      img {
        background-color: get-opacity($color-claro, 0.2);
      }

      &__text {
        a {
          color: $color-claro !important;
        }

        span {
          color: $color-variante;
        }
      }
    }

    &::-webkit-scrollbar-track {
      background: $color-bg-oscuro;
    }

    a {
      color: get-opacity($color-claro, 0.5);
    }
  }
}
