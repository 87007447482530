.ofapplicants {
  margin: 0 $space_half 0 0;
  width: 100%;
  height: 100%;
  min-width: 330px;
  min-height: 500px;
  border-radius: $space !important;
  background-color: $color-claro;
  background-color: $color-claro;

  &.dark {
    background-color: $color-bg-oscuro;

    .ofapplicants__list {
      &::-webkit-scrollbar-track {
        background: $color-bg-oscuro;
      }
    }
  }

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    .lottie {
      width: 35px;
      height: 35px;
    }
  }

  &__list {
    padding: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    &.empty {
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      p {
        margin: 240px 0 0 0;
      }
    }

    .item {
      position: relative;
      display: flex;
      width: 100%;
      height: 78px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      border-top: 1px solid get-opacity($color-variante, 0.2);

      &:first-child {
        border-top-left-radius: $space;
        border-top-right-radius: $space;
      }

      &:last-child {
        border-bottom: 1px solid get-opacity($color-variante, 0.2);
      }

      &:hover {
        background-color: get-opacity($color-variante, 0.1);
      }

      img {
        margin: 13px 20px;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        border: 2px solid transparent;
        pointer-events: none;
        transition: all 0.3s ease-in-out;
        background-color: get-opacity($color-variante, 0.5);
      }

      &__active {
        background-color: $color-primario;

        img {
          border: 2px solid $color-claro;
          background-color: get-opacity($color-claro, 0.2);
        }

        &:hover {
          background-color: get-opacity($color-primario, 1);
        }
      }

      &__text {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 100%;
        width: 90%;
        display: flex;
        padding: 10px 0px;

        h3 {
          font-size: $p;
          font-weight: $normal;
          margin-bottom: 0;
          text-transform: capitalize;
          display: flex;
        }
        p {
          font-size: 12px;
          font-weight: $light;
          margin-bottom: 0;
        }
      }

      .date {
        position: absolute;
        bottom: 5px;
        right: 10px;
        margin-bottom: 0;
      }
    }

    &::-webkit-scrollbar {
      width: 4px;
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      background: $color-bg-claro;
    }

    &::-webkit-scrollbar-thumb {
      background: $color-primario;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $color-bg-oscuro;
    }
  }
}
