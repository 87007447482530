.pagination {
  position: sticky;
  left: 0px;
  right: 0px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid get-opacity($color-oscuro, 0.1);
  width: 100%;
  height: 65px;
  animation: fadeIn 0.5s ease-in-out 0s both;
  transition: background-color 0.3s ease-in-out;
  background-color: $color-bg-claro;

  article {
    display: flex;
    align-items: center;
    margin: 0;
  }

  ul {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    max-width: 300px;
    list-style: none;

    li {
      padding: $space;
      margin: 0 $space_half;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      max-width: 37px;
      max-height: 37px;
      border-radius: 100%;
      font-family: $Fuente1;
      font-weight: $normal;
      text-align: center;
      cursor: pointer;
      box-shadow: 0px 12px 18px 0 rgba(25, 42, 70, 0.13);
      transition: all 0.3s ease-in-out;
      background-color: get-opacity($color-secundario, 0.8);

      a {
        padding: 20px 10px;
        text-decoration: none;
        color: $color-oscuro;
        @include font-size(15px);
        line-height: 15px;
      }

      &.selected {
        color: $color-claro;
        background-color: get-opacity($color-primario, 0.9);

        &:hover {
          background-color: get-opacity($color-primario, 0.9);
        }
      }

      &.disabled {
        cursor: not-allowed;
        color: $color-variante;
        background-color: get-opacity($color-variante, 0.4);

        a {
          color: get-opacity($color-variante, 0.5);
          cursor: not-allowed;
        }

        &:hover {
          background-color: get-opacity($color-variante, 0.4);
        }
      }

      &:hover {
        background-color: get-opacity($color-oscuro, 0.4);
      }
    }
  }

  &.dark {
    border-top: 1px solid get-opacity($color-secundario, 0.1);
    background-color: $color-oscuro;

    ul {
      li {
        box-shadow: 0px 12px 18px 0 rgba(25, 42, 70, 0.13);
        background-color: get-opacity($color-secundario, 0.1);

        a {
          color: $color-claro;
        }

        &.selected {
          color: $color-claro;
          background-color: get-opacity($color-primario, 0.9);

          &:hover {
            background-color: get-opacity($color-primario, 0.9);
          }
        }

        &.disabled {
          background-color: get-opacity($color-variante, 0.2);

          a {
            color: get-opacity($color-variante, 0.2);
          }

          &:hover {
            background-color: get-opacity($color-variante, 0.2);
          }
        }

        &:hover {
          background-color: get-opacity($color-primario, 0.4);
        }
      }
    }
  }
}
