.filter__select {
  cursor: pointer !important;
  @include font-size(14px);

  &--is-disabled {
    cursor: not-allowed !important;
  }

  &.mw-115 {
    min-width: 115px;
  }

  &__input-container {
    color: $color-oscuro !important;

    input {
      text-align: left !important;
      width: 100%;
      text-transform: uppercase !important;
    }
  }

  &__control {
    border-radius: 0 !important;
    font-weight: $normal !important;
    border: none !important;
    box-shadow: none !important;
    cursor: pointer;
    background-color: get-opacity($color-oscuro, 0) !important;

    &--is--focused {
      background-color: get-opacity($color-oscuro, 0.2) !important;
    }

    &--is-disabled {
      cursor: not-allowed !important;
      border-bottom: 1px solid get-opacity($color-oscuro, 0.2) !important;
      background-color: get-opacity($color-oscuro, 0.05) !important;

      .filter__select__dropdown-indicator {
        color: get-opacity($color-oscuro, 0.2) !important;
      }
    }
  }

  &__single-value {
    color: $color-oscuro !important;
    transition: all 0.5s ease-in-out !important;

    &--is-disabled {
      cursor: not-allowed !important;
      color: get-opacity($color-oscuro, 0.2) !important;
    }
  }

  &__value-container {
    color: $color-oscuro !important;
  }

  &__indicators {
    cursor: pointer !important;
    span {
      display: none !important;
    }
  }

  &__menu {
    margin-top: 0 !important;
    border-radius: 0 !important;
    background-color: get-opacity($color-claro, 1) !important;

    &-list {
      padding: 0 !important;
      max-height: 200px !important;
      border-bottom: 0.5px solid get-opacity($color-oscuro, 0.2);
      background-color: get-opacity($color-claro, 0) !important;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background: get-opacity($color-oscuro, 0.1);
      }

      &::-webkit-scrollbar-thumb {
        background: get-opacity($color-primario, 0.8);
      }

      &::-webkit-scrollbar-thumb:hover {
        background: get-opacity($color-primario, 1);
      }
    }
  }

  &__option {
    padding: $space_half !important;
    cursor: pointer !important;
    color: $color-oscuro !important;
    border-bottom: 0.5px solid get-opacity($color-claro, 0.2);
    background-color: get-opacity($color-claro, 0.9) !important;

    &:last-child {
      border-bottom: 0.5px solid get-opacity($color-claro, 0);
    }
  }

  &__option--is-focused {
    background-color: get-opacity($color-oscuro, 0.1) !important;
  }

  &__option--is-selected {
    background-color: get-opacity($color-primario, 0.5) !important;
  }

  &.dark {
    background-color: transparent !important;

    .filter__select__input-container {
      color: $color-claro !important;
    }

    .filter__select__control {
      background-color: get-opacity($color-oscuro, 0) !important;

      &--is--focused {
        background-color: get-opacity($color-claro, 0.2) !important;
      }

      &--is-disabled {
        border-bottom: 1px solid get-opacity($color-claro, 0.2) !important;
        background-color: get-opacity($color-claro, 0.05) !important;

        .filter__select__dropdown-indicator {
          color: get-opacity($color-claro, 0.2) !important;
        }
      }
    }

    .filter__select__single-value {
      color: $color-claro !important;

      &--is-disabled {
        color: get-opacity($color-claro, 0.2) !important;
      }
    }

    .filter__select__value-container {
      color: $color-claro !important;
    }

    .filter__select__menu {
      background-color: get-opacity($color-oscuro, 1) !important;

      &-list {
        background-color: get-opacity($color-oscuro, 0) !important;

        &::-webkit-scrollbar-track {
          background: get-opacity($color-claro, 0.1);
        }
      }
    }

    .filter__select__option {
      color: $color-claro !important;
      border-bottom: 0.5px solid get-opacity($color-claro, 0.2);
      background-color: get-opacity($color-oscuro, 0.9) !important;

      &:last-child {
        border-bottom: 0.5px solid get-opacity($color-claro, 0);
      }
    }

    .filter__select__option--is-focused {
      background-color: get-opacity($color-claro, 0.1) !important;
    }

    .filter__select__option--is-selected {
      background-color: get-opacity($color-primario, 0.6) !important;
    }
  }
}
