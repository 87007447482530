.comments {
  position: relative;
  height: 100%;
  margin-bottom: 1rem;

  &__container {
    padding: $space 0 0;
    position: relative;
    height: 100%;
    max-height: 200px;
    overflow-y: auto;
    border-radius: 5px;
    background-color: $color-bg-claro;

    .empty {
      padding: $space 0 0 0;
      width: 100%;
      text-align: center;
      grid-column: 1 / span 2;
    }

    &::-webkit-scrollbar {
      width: 4px;
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      background: $color-bg-claro;
    }

    &::-webkit-scrollbar-thumb {
      background: $color-primario;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $color-bg-oscuro;
    }
  }

  &__comment {
    padding: $space $space 0;
    width: 100%;
    height: 100%;
    @include display-grid;
    @include grid-template-columns(40px auto);

    img {
      width: 30px;
      border-radius: 50%;
      pointer-events: none;
      object-fit: cover;
      object-position: center;
      cursor: pointer !important;
    }

    &__body {
      padding: $space_half;
      border-radius: $space_half;
      background-color: get-opacity($color-variante, 0.2);

      p {
        margin: 0;
        cursor: pointer;
        font-weight: $medium;
        text-transform: capitalize;
        @include font-size(12px);
      }

      span {
        padding: $space 0 0;
        @include font-size(12px);
      }
    }

    &__footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      grid-column: 2;

      small {
        @include font-size(10px);
      }

      button {
        padding: 0;
        margin: 0 $space_half;
        font-family: $Fuente1;
        border: none;
        outline: none !important;
        cursor: pointer;
        color: $color-claro;
        background-color: transparent;
        @include font-size(10px);

        &:last-child {
          color: $color-error;
        }
      }
    }
  }

  &__form {
    padding: $space_half;
    position: sticky;
    bottom: 0px;
    margin: 0 auto;
    background-color: $color-bg-claro;
    @include display-grid;
    @include grid-template-columns(90% 10%);

    input {
      padding: 0.3rem $space;
      width: 100%;
      border: none;
      outline: none !important;
      border-radius: 5px 0 0 5px;
      font-family: $Fuente1;
      color: $color-oscuro;
      border: 1px solid get-opacity($color-oscuro, 0.2);
      background-color: get-opacity($color-secundario, 0.5);
    }

    button {
      padding: 0.3rem $space;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      width: 100%;
      background-color: $color-primario;
      color: $color-claro;
      border: none;
      outline: none !important;
      border-radius: 0 5px 5px 0;
      cursor: pointer;

      &:hover {
        background-color: get-opacity($color-primario, 0.5);
      }
    }
  }

  &.dark {
    .comments__container {
      background-color: get-opacity($color-oscuro, 1);
      &::-webkit-scrollbar-track {
        background: $color-bg-oscuro;
      }
    }

    .comments__comment {
      p {
        &.name {
          color: $color-claro;
        }

        &:last-child {
          color: $color-variante;
        }
      }

      span {
        color: $color-claro;
      }
    }

    .comments__form {
      background-color: $color-oscuro;

      input {
        color: $color-bg-claro;
        background-color: $color-bg-oscuro;
      }
    }
  }
}
