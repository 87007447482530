.login {
  padding: 0 $space_x5;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  user-select: none;
  background-image: url('../../../images/static/login_bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  backdrop-filter: blur(10px);

  &__logo {
    padding: 0 0 0 10%;
    display: flex;
    align-items: center;

    img {
      margin: 0 $space_x2 0 0;
      width: 100px;
      aspect-ratio: 1/1;
    }

    h1 {
      font-family: $Fuente2;
      font-weight: $medium;
      text-shadow: 1px 1px 5px get-opacity($color-oscuro, 0.5);
      color: $color-claro;
      @include font-size(80px);
    }
  }

  &__form {
    padding: $space_x6 $space_x3 $space_x5;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
    box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
    transition:
      box-shadow 0.5s,
      opacity 0.5s;
    will-change: transform;
    border-radius: $space;
    background-color: $color-claro;
    margin: 0 10% 0 0;

    &:hover {
      box-shadow: 0px 30px 100px -10px rgba(0, 0, 0, 0.4);
    }

    h2 {
      margin-bottom: $space_x3;
      text-align: center;
      color: $color-oscuro;
      @include font-size(30px);
    }

    p {
      width: 100%;
      .error {
        color: $color-error;
        border: 1px solid $color-error;
        background-color: get-opacity($color-error, 0.1);
      }
    }

    input {
      padding: $space $space_x2;
      width: 100%;
      outline: none;
      font-family: $Fuente1;
      border: 1px solid $color-secundario;
      border-radius: $space;
      color: $color-oscuro;
      background-color: $color-claro;
    }

    span {
      color: $color-error;
      @include font-size(11px);
    }

    a {
      width: auto;
      margin: 0 auto;
      text-align: center;
      text-decoration: underline;
      color: blue;
      @include font-size(12px);
    }

    button {
      padding: $space $space_x4;
      margin: 0 auto;
      font-family: $Fuente1;
      border: none;
      border-radius: $space_half;
      max-width: fit-content;
      outline: none;
      cursor: pointer;
      transition: all 0.5s ease-in-out;
      color: $color-claro;
      background-color: $color-oscuro;

      .lottie {
        margin: 0;
        width: 35px;
      }

      img {
        margin-right: $space_x2;
        width: 20px;
        height: 20px;
      }

      &:disabled {
        padding: 1px $space_x2;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: get-opacity($color-oscuro, 0.9);

        &:hover {
          background-color: get-opacity($color-oscuro, 0.9);
        }
      }

      &:hover {
        background-color: get-opacity($color-oscuro, 0.8);
      }
    }

    hr {
      margin: $space_x2 0;
      width: 100%;
      border-color: get-opacity($color-oscuro, 0.1);
    }
  }

  &__copy {
    display: flex;
    align-items: center;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, -50%);
    color: get-opacity($color-oscuro, 0.3);
    @include font-size(13px);
  }
}
