// Espaciado
$space: 10px;
$space_half: 5px;
$space_x2: 20px;
$space_x3: 30px;
$space_x4: 40px;
$space_x5: 50px;
$space_x6: 60px;
$space_x7: 70px;
$space_x8: 80px;
$space_x9: 90px;
$space_x10: 100px;
$space_x11: 110px;
$space_x12: 120px;
$space_x13: 130px;
$space_x14: 140px;
$space_x15: 150px;
