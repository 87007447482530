::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: $color-bg-claro;
}

::-webkit-scrollbar-thumb {
  background: $color-primario;
}

::-webkit-scrollbar-thumb:hover {
  background: $color-bg-oscuro;
}

:root {
  --main-font-size: 15px;
}

@include media(desktop-s) {
  ::-webkit-scrollbar {
    width: 10px;
  }

  :root {
    --main-font-size: 13px;
  }
}

@include media(desktop-m) {
  :root {
    --main-font-size: 14px;
  }
}

@include media(desktop-ml) {
  :root {
    --main-font-size: 15px;
  }
}

@include media(desktop-l) {
  :root {
    --main-font-size: 15px;
  }
}

@include media(desktop-xl) {
  :root {
    --main-font-size: 16px;
  }
}

html,
body {
  padding: 0;
  margin: 0;
  min-width: 1200px;
  font-size: var(--main-font-size);
  font-family: $Fuente1 !important;
  scroll-behavior: smooth;
  box-sizing: border-box;
  color: $color-oscuro;
  background-color: $color-bg-claro;
  transition: background-color 0.3s ease-in-out;

  &.dark {
    color: $color-claro;
    background-color: $color-oscuro;
  }
}
