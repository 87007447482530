.settings {
  padding: $space_x2 $space_x3;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 70vh;
  user-select: none;
  color: $color-oscuro;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      transition: all 0.3s ease-in-out;
      @include font-size(24px);
    }
  }

  &__tabs {
    display: flex;
    align-items: center;
    margin: $space_x3 0;
    border-bottom: 2px solid get-opacity($color-secundario, 1);

    ul {
      padding: 0;
      display: flex;
      margin: 0;
      width: 100%;
      list-style: none;
    }

    li {
      position: relative;
      margin: 0 $space_x4 0 0;
      cursor: pointer;
      user-select: none;
      text-align: left;

      .underline {
        position: absolute;
        bottom: -2px;
        left: 0;
        right: 0;
        height: 2px;
        background-color: $color-primario;
      }
    }

    p {
      padding: $space 0;
      display: flex;
      align-items: center;
      margin: 0;
      transition: all 0.3s ease-in-out;
      @include font-size(16px);

      span {
        padding: $space_half;
        margin-left: $space;
        border-radius: 5px;
        color: $color-primario;
        background-color: get-opacity($color-primario, 0.2);
        @include font-size(14px);
      }
    }

    button {
      padding: $space_half $space_x2 $space_half $space;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-family: $Fuente1;
      border: none;
      border-radius: $space_half;
      outline: none !important;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      animation: fadeIn 0.3s ease-in-out both;
      color: $color-claro;
      background-color: $color-error;
      @include font-size(18px);

      span {
        padding: $space $space;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: $space_half;
        width: 22px;
        height: 22px;
        border-radius: 100%;
        background-color: get-opacity($color-bg-claro, 0.2);
        @include font-size(15px);
        line-height: 15px;
      }

      &:hover {
        opacity: 0.5;
      }
    }
  }

  &__container {
    padding: 0;
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: $space_half;
    background-color: $color-claro;
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column: 1 / span-1;
    width: 100%;
    height: 500px;

    .lottie {
      width: 35px;
      height: 35px;
    }
  }

  &__error {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column: 1 / span-1;
    width: 100%;
    height: 700px;

    .lottie {
      width: 500px;
      height: 500px;
    }
  }

  &.dark {
    color: $color-claro;

    .settings__container {
      background-color: $color-bg-oscuro;
    }
  }

  @include media(desktop-ml) {
    padding: $space_x2 $space_x3;

    &__container {
      gap: $space_x2;
      @include grid-template-columns(repeat(3, minmax(260px, 1fr)));
    }
  }

  @include media(desktop-l) {
    padding: $space_x2 $space_x3;

    &__container {
      gap: $space_x2;
      @include grid-template-columns(repeat(4, minmax(260px, 1fr)));
    }
  }
}
