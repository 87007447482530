.aplicants {
  padding: $space_x2 $space_x3;
  width: 100%;
  height: calc(100vh - 80px);
  overflow-x: hidden;

  &__container {
    display: flex;
    gap: 1rem;
    height: calc(100% - 45px);
  }
}
