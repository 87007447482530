.modal {
  padding: $space_x2;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100vw;
  height: 100%;
  backdrop-filter: blur(4px);
  user-select: none;
  transition: all 0.5s ease-in-out;
  -webkit-backdrop-filter: blur(4px);
  animation: fadeIn 0.8s ease-in-out both;
  background-color: get-opacity($color-bg-oscuro, 0.5);

  &.prompt {
    align-items: center;
    justify-content: center;
  }

  &.dark {
    background-color: get-opacity($color-bg-claro, 0.2);
  }

  &__container {
    padding: $space_x2;
    border-radius: $space;
    width: 100%;
    max-width: 400px;
    color: $color-oscuro;
    background-color: $color-bg-claro;
  }

  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    & > div {
      padding: $space_half;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      color: $color-error;
      @include font-size(30px);
      background-color: get-opacity($color-error, 0.4);
    }

    button {
      display: flex;
      align-items: center;
      font-family: $Fuente1;
      outline: none !important;
      border: none;
      border-radius: $space_half;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      color: $color-oscuro;
      background-color: transparent;
      @include font-size(30px);

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &__body {
    padding: $space_x3 0 $space_x3;
    max-width: 350px;

    p {
      margin: 0;
      font-weight: $medium;
      @include font-size(18px);
    }

    span {
      margin: 0;
      @include font-size(12px);
      line-height: 11px !important;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      padding: $space_half $space_x2;
      display: flex;
      align-items: center;
      margin: 0 $space;
      font-family: $Fuente1;
      border: 1px solid $color-secundario;
      outline: none !important;
      border-radius: $space_half;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      color: $color-oscuro;
      background-color: transparent;

      &:last-child {
        border: 1px solid $color-error;
        color: $color-claro;
        background-color: $color-error;
      }

      &:hover {
        opacity: 0.8;
      }

      & > div {
        margin-right: $space;
        width: 12px;
        height: 100%;
      }
    }
  }
}
