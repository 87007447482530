.header {
  height: 80px;
  padding: $space_x3;
  position: sticky;
  top: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  transition: all 0.3s ease-in-out;
  color: $color-oscuro;
  background-color: $color-claro;
  box-shadow: 0px 0px 5px 1px $color-variante;

  &.dark {
    background-color: $color-bg-oscuro;
    box-shadow: 0px 0px 5px 1px $color-oscuro;
    .header__logo {
      color: $color-claro;
    }
  }

  &__logo{
    width: 70%;
    height: 80px;
    display: flex;
    align-items: center;
    margin-bottom: $space_x3;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
    opacity: 0;
    transition: all 0.3s ease-in-out;


    img{
      height: 30px;
    }
    h3{
      margin-left: .8rem;
      margin-bottom: unset;
      font-weight: $medium;
    }

    &.active {
      opacity: 100%;
      transition: all 0.3s ease-in-out 0.3s;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: $space_x3;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    color: $color-secundario;
    background-color: transparent;
    @include font-size(20px);
  }

  &__profile {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 43px;
    height: 43px;
    text-transform: none;
    text-decoration: none;
    border-radius: 100%;
    background-color: get-opacity($color-primario, 0.2);

    img {
      width: 35px;
      height: 35px;
      aspect-ratio: 1/1;
      object-fit: cover;
      border-radius: 100%;
      background-color: get-opacity($color-primario, 0.3);
    }
  }
}
