.details {
  padding: $space_x4 $space_x4;
  width: 100%;
  height: 100%;
  user-select: none;
  color: $color-oscuro;

  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    animation: fadeIn 0.5s ease-in-out both;

    a {
      padding: $space;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: $space_x3;
      border-radius: 100%;
      cursor: pointer;
      color: $color-claro;
      background-color: get-opacity($color-bg-oscuro, 0.5);
      @include font-size(24px);
    }

    span {
      display: flex;
      align-items: center;
      color: $color-oscuro;
      @include font-size(16px);

      svg {
        fill: $color-oscuro;
        @include font-size(24px);
      }
    }

    h2 {
      margin: $space_half 0;
      transition: all 0.3s ease-in-out;
      @include font-size(24px);
      line-height: 24px;
    }
  }

  &__alert {
    padding: $space_half;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    width: 100%;
    color: $color-claro;
    background-color: $color-variante-dos;

    &.error {
      background-color: $color-error;
    }

    p {
      display: flex;
      align-items: center;
      margin: 0 $space;
      font-weight: $normal;
      @include font-size(15px);
    }

    span {
      color: $color-claro !important;
      @include font-size(12px);
    }
  }

  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 700px;
  }

  &__tabs {
    margin: $space_x3 0 $space 0;
    border-bottom: 2px solid get-opacity($color-secundario, 1);

    ul {
      padding: 0;
      display: flex;
      margin: 0;
      width: 100%;
      list-style: none;
    }

    li {
      position: relative;
      margin: 0 $space_x4 0 0;
      cursor: pointer;
      user-select: none;
      text-align: left;

      .underline {
        position: absolute;
        bottom: -2px;
        left: 0;
        right: 0;
        height: 2px;
        background-color: $color-primario;
      }
    }

    p {
      padding: $space 0;
      display: flex;
      align-items: center;
      margin: 0;
      transition: all 0.3s ease-in-out;
      @include font-size(16px);

      span {
        padding: $space_half;
        margin-left: $space;
        border-radius: 5px;
        color: $color-primario;
        background-color: get-opacity($color-primario, 0.2);
        @include font-size(14px);
      }
    }
  }

  &__container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
  }

  &__column {
    width: 100%;
    height: 100%;

    &:nth-child(2) {
      margin-left: $space;
      max-width: 400px;
    }
  }

  &__content {
    padding: $space_x3;
    margin-bottom: $space_x2;
    width: 100%;
    height: 100%;
    border-radius: $space_half;
    background-color: $color-claro;

    h2,
    h3 {
      padding: 0 0 $space_x2 0;
      margin: 0;
      font-weight: $normal;
      @include font-size(24px);
    }

    h3 {
      padding: 0 0 $space_half 0;
      margin-bottom: $space_x2;
      border-bottom: 1px solid get-opacity($color-secundario, 0.1);
    }

    &__grid {
      @include display-grid;
      @include grid-template-columns(50% 50%);

      p {
        margin: 0;
        font-weight: $light;
        @include font-size(14px);

        b {
          font-weight: $medium;
        }
      }

      span {
        color: $color-check;

        &[data-active='false'] {
          color: $color-error;
        }
      }
    }

    &__row {
      display: flex;
      align-items: center;
      justify-content: space-between;

      button {
        padding: $space_half;
        width: 100px;
        font-weight: $light;
        font-family: $Fuente1;
        border: none;
        outline: none !important;
        border-radius: $space_half;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        color: $color-claro;
        background-color: $color-primario;
        @include font-size(13px);

        &:nth-child(2) {
          background-color: $color-error;
        }

        &[data-active='false'] {
          color: $color-claro;
          background-color: $color-check;
        }

        &[data-active='true'] {
          color: $color-oscuro;
          background-color: $color-secundario;
        }

        &:disabled{
          cursor: not-allowed;
        }

        &:hover {
          opacity: 0.8;
        }
      }
    }

    &__column {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
    }

    &__info {
      padding: 0.8rem 0.5rem;
      display: flex;
      align-items: flex-start;
      margin: 0 0 $space 0;
      width: 100%;
      transition: all 0.3s ease-in-out;
      text-decoration: none !important;
      cursor: pointer;
      line-height: 10px;
      border-radius: $space_half;
      background-color: $color-bg-claro;

      img {
        margin-right: $space;
        width: 30px;
        height: 30px;
        pointer-events: none;
        border-radius: 100%;
      }

      & > div {
        color: $color-oscuro;

        p {
          margin: 0;
          @include font-size(15px);
        }

        span {
          margin: 0;
          white-space: break-spaces;
          color: get-opacity($color-oscuro, 1);
          @include font-size(12px);
        }
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &.dark {
    color: $color-claro;

    .details__header {
      a {
        color: $color-claro;
        background-color: $color-bg-oscuro;
      }

      span {
        color: $color-secundario;

        svg {
          fill: $color-claro;
        }
      }
    }

    .details__content {
      color: $color-claro;
      background-color: $color-bg-oscuro;

      &__info {
        color: $color-claro;
        background-color: $color-oscuro;

        & > div {
          color: $color-claro;

          span {
            color: get-opacity($color-claro, 0.5);
          }
        }
      }
    }
  }

  @include media(desktop-l) {
    padding: $space_x4 $space_x3;

    &__container {
      gap: $space_x2;
      @include grid-template-columns(repeat(4, minmax(260px, 25%)));
    }
  }
}
