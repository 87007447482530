.aplicant {
  width: 100%;
  min-width: 350px;
  max-width: 370px;
  background-color: $color-claro;
  border-radius: 5px;
  padding: 1rem;
  padding-bottom: 0;
  height: max-content;
  transition: all 0.3s ease;

  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 500px;
    width: 100%;

    .loader {
      width: 35px;
      height: 35px;
    }
  }

  &.dark {
    background-color: $color-bg-oscuro;

    h3,
    p,
    span {
      color: $color-variante;
    }

    .aplicant__vacant {
      & > div:not(:last-child),
      .aplicant__vacant__info {
        background-color: $color-oscuro;
      }

      &__info {
        &:hover {
          background-color: get-opacity($color-oscuro, 0.5);
        }
      }
      .title {
        color: $color-claro;
      }

      &__details {
        p {
          span {
            color: $color-claro;
          }
        }
      }
    }
  }

  h2 {
    font-size: 24px;
    font-weight: $normal;
    margin-bottom: 0;
    text-transform: capitalize;
    display: flex;
  }

  hr {
    border: 1px solid get-opacity($color-variante, 0.2);
    margin: 0.8rem 0;
  }

  h3 {
    font-size: $p;
    font-weight: $normal;
  }

  &__container {
    display: flex;
    flex-direction: column;

    span {
      display: flex;
      justify-content: space-between;

      p {
        width: auto;

        &:last-child {
          width: 60%;
        }
      }
    }

    p {
      margin-bottom: 0.3rem;
      font-weight: $light;
      font-size: 12px;

      strong {
        font-weight: $medium;
        margin-right: 0.3rem;
      }
    }
  }

  &__vacant {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;

    h3 {
      align-self: flex-start;
      margin-bottom: 0;
    }

    & > div,
    .aplicant__vacant__info {
      width: 100%;
      border-radius: 5px;

      &:not(:last-child) {
        background-color: $color-bg-claro;
      }
    }

    &__info {
      display: flex;
      gap: 1rem;
      align-items: center;
      padding: 0.8rem 0.5rem;
      cursor: pointer;

      img {
        height: 40px;
        width: 40px;
        grid-row: 1 / 3;
        pointer-events: none;
      }

      p {
        margin-bottom: 0;

        &.title {
          text-decoration: underline;
          color: $color-oscuro;
        }
      }

      &:hover {
        background-color: get-opacity($color-bg-claro, 0.5);
      }
    }
    .empty {
      padding: 0.5rem;
      text-align: center;
      width: 100%;
      p {
        margin-bottom: 0;
      }
    }

    &__details {
      display: flex;
      justify-content: space-around;
      padding: 0.3rem;

      p {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 0;
        height: 100%;
        font-weight: $medium;

        span {
          font-weight: $light;
          color: $color-oscuro;
        }
      }
    }

    &__links {
      padding: 0.5rem;
      justify-content: space-around;
      background-color: none;
      @include display-grid;
      @include grid-template-columns(repeat(3, 1fr));

      a {
        padding: $space_half 0 0;
        display: flex;
        align-items: center;
        white-space: nowrap;
        gap: 0.2rem;
        text-decoration: underline;
        transition: all 0.3s ease;
        color: $color-primario;
        @include font-size(12px);

        &::first-letter {
          text-transform: uppercase;
        }

        &:hover {
          color: get-opacity($color-secundario, 0.8);
        }
      }
    }
  }
}
