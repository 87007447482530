/* Media Queries Mixin
**********************/

@mixin media ($breakpoint) {
  @if $breakpoint==mobile-max {
      @media only screen and (min-width: 450px) {
          @content;
      }
  }
  @if $breakpoint==tablet {
      @media only screen and (min-width: 768px) {
          @content;
      }
  }
  @else if $breakpoint==desktop-s {
      @media only screen and (min-width: 992px) {
          @content;
      }
  }
  @else if $breakpoint==desktop-m {
      @media only screen and (min-width: 1200px) {
          @content;
      }
  }
  @else if $breakpoint==desktop-ml {
      @media only screen and (min-width: 1366px) {
          @content;
      }
  }
  @else if $breakpoint==desktop-l {
      @media only screen and (min-width: 1480px) {
          @content;
      }
  }
  @else if $breakpoint==desktop-xl {
      @media only screen and (min-width: 1780px) {
          @content;
      }
  }
  @else if $breakpoint==mobile-only {
      @media only screen and (max-width: 768px) {
          @content;
      }
  }
  @else if $breakpoint==tablet-p-only {
      @media only screen and (min-width: 768px) and (max-width: 992px) and (orientation: portrait) {
          @content;
      }
  }
  @else if $breakpoint==tablet-l-only {
      @media only screen and (min-width: 768px) and (max-width: 992px) and (orientation: landscape) {
          @content;
      }
  }
  @else if $breakpoint==desktop-s-only {
      @media only screen and (min-width: 768px) and (max-width: 1200px) {
          @content;
      }
  }
  @else if $breakpoint==desktop-m-only {
      @media only screen and (min-width: 1200px) and (max-width: 1480px) {
          @content;
      }
  }
}