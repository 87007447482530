// Brand colors
$color-primario: #FF8724;
$color-secundario: #C4C4C4;

// Variant colors
$color-variante: #b2b2b2;
$color-variante-dos: #F24E1E;
$color-variante-tres: #262626;

// Alerts colors
$color-check: #38CB89;
$color-error: #BF1214;

// Boolean colors
$color-claro: #FFFFFF;
$color-oscuro: #181818;

// Background colors
$color-bg-claro: #EFEFEF;
$color-bg-oscuro: #292D32;

