.account {
  padding: $space_x2 $space_x2;

  &__header {
    padding: 0 0 $space_half;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $color-oscuro;

    h2 {
      @include font-size(20px);
    }
  }

  &.dark {
    .account__header {
      border-bottom: 1px solid $color-secundario;
    }
  }
}
