.users {
  padding: $space_x2 0 0;

  &__header {
    padding: 0 $space_x2 $space;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $color-oscuro;

    h2 {
      @include font-size(20px);
    }

    button {
      padding: $space_half $space;
      border-radius: $space_half;
      border: none !important;
      font-family: $Fuente1;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      color: $color-claro;
      background-color: $color-primario;

      &:hover {
        opacity: 0.5;
      }
    }
  }

  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__item {
    padding: 0 $space_x2;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid get-opacity($color-secundario, 0.2);
    transition: all 0.3s ease-in-out;
    @include display-grid;
    @include grid-template-columns(60px 1fr 80px);

    &:hover {
      background-color: get-opacity($color-secundario, 0.2);
    }

    img {
      margin-right: $space;
      width: 50px;
      height: 50px;
      object-fit: cover;
      border-radius: 100%;
      cursor: pointer;
      background-color: $color-oscuro;
    }

    &__info {
      padding: $space_x2 0;
      cursor: pointer;

      P {
        margin: 0 0 $space_half;
        font-weight: $medium;
        @include font-size(14px);
        line-height: 14px;
      }

      span {
        margin: 0;
        @include font-size(14px);
        line-height: 14px;
      }
    }

    &__actions {
      display: flex;
      align-items: center;
      margin-left: auto;

      & > div {
        display: flex;
      }

      button {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        width: 40px;
        height: 40px;
        border: none;
        border-radius: 100%;
        outline: none;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        color: $color-primario;
        background-color: transparent;
        @include font-size(25px);

        &:last-child {
          color: $color-error;
        }

        &:hover {
          opacity: 0.5;
        }
      }
    }

    &__selected {
      background-color: get-opacity($color-secundario, 0.5);

      &:hover {
        background-color: get-opacity($color-secundario, 0.5);
      }
    }

    &:last-child {
      border: none;
    }
  }
}
