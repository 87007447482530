@use 'sass:math'; // Propiedad de sass para operaciones matemáticas

@function t($key) {
  @return map-get($theme-map, $key);
}

//Funcion para el texto responsivo
@function calculateRem($size) {
  $remSize: math.div($size, 16px);
  @return $remSize * 1rem;
}

// Dependiendo del tamaño del texto se selecciona un alto de linea automatica
@mixin font-size($size) {
  font-size: calculateRem($size);
  line-height: calculateRem($size) * 1.5;
}

// Opacidad
@function get-opacity($color, $amount) {
  @return rgba($color, $amount);
}
