.dropdown {
  position: fixed;
  top: 70px;
  right: 50px;
  width: auto;
  border-radius: $space_half;
  animation: fadeIn 0.5s ease-in-out both;
  box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
  background-color: $color-claro;

  &__triangle {
    position: absolute;
    top: -5px;
    right: 25px;
    width: 15px;
    height: 5px;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    background-color: $color-claro;
  }

  &__container {
    svg {
      margin-right: $space_half;
    }

    a {
      padding: $space;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
      text-decoration: none;
      border-top-left-radius: $space_half;
      border-top-right-radius: $space_half;
      transition: all 0.3s ease-in-out;
      color: $color-oscuro;
      @include font-size(14px);

      &:hover {
        background-color: $color-secundario;
      }
    }

    button {
      padding: $space;
      margin: 0;
      font-family: $Fuente1;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      border-bottom-left-radius: $space_half;
      border-bottom-right-radius: $space_half;
      color: $color-error;
      @include font-size(14px);

      &:hover {
        background-color: $color-secundario;
      }
    }
  }
}
