.aside {
  $transition-time: 0.3s ease-in-out;
  $transition-delay: 0s;

  width: 260px;
  height: 100vh;

  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 3;

  display: flex;
  flex-direction: column;
  align-items: center;

  transition: all $transition-time;
  color: $color-oscuro;
  background-color: $color-claro;

  &.dark {
    background-color: $color-bg-oscuro;
    color: $color-claro;

    a {
      color: get-opacity($color-claro, 0.5);
    }

    .aside__toggle {
      color: $color-claro;
      background-color: get-opacity($color-claro, 0.2);
    }
  }

  &__toggle {
    padding: 4px;
    display: flex;
    align-items: center;
    position: absolute;
    top: 25px;
    right: 0px;
    border: none;
    outline: none !important;
    border-top-left-radius: $space_half;
    border-bottom-left-radius: $space_half;
    cursor: pointer;
    transition: all $transition-time;
    color: $color-oscuro;
    background-color: get-opacity($color-primario, 0.4);
    @include font-size(25px);

    svg {
      transition: all $transition-time;
    }

    &:hover {
      background-color: get-opacity($color-secundario, 0.6);
    }
  }

  &__logo {
    width: 70%;
    height: 80px;
    display: flex;
    align-items: center;
    margin-bottom: $space_x3;

    opacity: 100%;
    transition: opacity $transition-time 0.3s;

    img {
      height: 30px;
    }
    h3 {
      margin-left: 0.8rem;
      margin-bottom: unset;
      font-weight: $medium;
    }
  }

  &__menu {
    width: 100%;
    padding: 0 $space;
    margin-bottom: $space_x3;
    overflow: hidden;
    transition: all $transition-time $transition-delay;

    &__title {
      text-transform: uppercase;
      font-weight: $light;
      margin-bottom: $space;
      margin-left: $space_x2;
      font-size: $p;
      transition: opacity $transition-time;
    }

    &__items {
      text-decoration: none;
      list-style: none;
      padding: unset;
      margin-bottom: $space_x3;
      transition: all $transition-time;

      li {
        color: $color-oscuro;

        a {
          width: 240px;
          height: 48px;
          position: relative;
          display: block;
          border-radius: 5px;
          text-decoration: none;
          // transition: color $transition-time;
          color: get-opacity($color-oscuro, 0.7);

          svg {
            height: 20px;
            width: 20px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 20px;
          }

          span {
            width: 170px;
            margin-top: 0;
            position: absolute;
            left: 55px;
            top: 50%;
            transform: translateY(-50%);
            // transition: all $transition-time;
            transition:
              opacity $transition-time $transition-delay,
              left $transition-time,
              width $transition-time,
              top $transition-time;
          }

          &.active {
            color: $color-claro;
            background-color: $color-primario;
            transition: all $transition-time;

            &:hover {
              background-color: get-opacity($color-primario, 1);
            }
          }

          &:hover {
            background-color: get-opacity($color-secundario, 0.2);
          }
        }
      }
    }
  }

  &--closed {
    transition: all $transition-time;
    width: 60px;
    overflow: hidden;

    .aside__toggle {
      right: 50%;
      transform: translateX(50%);
      border-top-right-radius: $space_half;
      border-bottom-right-radius: $space_half;
      transition: all $transition-time;

      & > svg {
        transition: all $transition-time;
        transform: rotate(180deg);
      }
    }

    .aside__logo {
      position: relative;
      pointer-events: none;
      opacity: 0;
      // esta transicion es más rapida para que desaparezca el logo a tiempo
      transition: opacity 0.1s ease-out;
    }

    .aside__menu {
      padding: 0;

      h4 {
        opacity: 0;
        transition: opacity $transition-time;
      }

      &__items {
        li {
          a {
            width: 100%;

            svg {
              height: 22px;
              width: 22px;
              left: 20px;
            }

            span {
              opacity: 0;
              transition: opacity 0.3s ease-out;
            }
          }
        }
      }
    }
  }
}
