.groupselect {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.dark {
    .groupselect__label {
    color: $color-primario !important;
    }

    .groupselect__badge {
      color: $color-primario !important;
      background-color: get-opacity($color-secundario, 0.2);
    }
  }

  &__label {
    color: $color-primario !important;
  }

  &__badge {
    padding: $space_half $space;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 1;
    font-weight: normal;
    line-height: 1;
    border-radius: 100%;
    text-align: center;
    color: $color-claro !important;
    background-color: $color-primario;
    @include font-size(12px);
  }
}
